import { template as template_14e194043f164bdcb3a1bbb680bff0db } from "@ember/template-compiler";
const WelcomeHeader = template_14e194043f164bdcb3a1bbb680bff0db(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
